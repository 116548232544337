import React from 'react';
import { compose } from 'recompose';
import Layout from '../components/Layout';
import { withAuthorization, withEmailVerification } from '../utils/Session';
import AdminDashboard from '../components/Dashboard';

const condition = (authUser) => !!authUser;
const AdminDashboardPage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(AdminDashboard);

export default () => {
  return (
    <Layout>
      <AdminDashboardPage />
    </Layout>
  );
};
