import React, { Component } from 'react';

export interface IDynamicHeightProps {
  children?: React.ReactNode;
  isExpanded?: boolean;
  animationSpeed?: number;
  className?: string;
}

interface IDynamicHeightState {
  isAnimating: boolean;
  maxHeight: number;
  opacity: number;
}

class DynamicHeight extends Component<IDynamicHeightProps, IDynamicHeightState> {
  animatingTimeout?: any;
  contentObserver: MutationObserver;
  contentWrapper = React.createRef<HTMLDivElement>();

  constructor(props: IDynamicHeightProps) {
    super(props);

    this.state = {
      isAnimating: false,
      maxHeight: 0,
      opacity: 0,
    };
  }

  componentDidMount() {
    const { isExpanded } = this.props;

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleHeightChange);
    }
    // If by default it is expanded, calculate max-height.
    if (isExpanded) {
      this.handleHeightChange();
    }

    this.contentObserver = new MutationObserver((mutationsList: any) => {
      // Use traditional 'for loops' for IE 11
      this.handleHeightChange();
    });

    if (this.contentWrapper.current) {
      this.contentObserver.observe(this.contentWrapper.current, {
        childList: true,
        subtree: true,
      });
    }
  }

  componentDidUpdate(prevProps: IDynamicHeightProps) {
    const { isExpanded, animationSpeed } = this.props;

    if (isExpanded !== prevProps.isExpanded || this.props.children !== prevProps.children) {
      const { scrollHeight = 0 } = this.contentWrapper.current || {};
      // Set reasonable limits for animation duration.
      // Determine maxHeight at the time of the click,
      // so that it's the most accurate (consider the situation
      // when content has changed after component mounted).

      const maxHeight = isExpanded ? scrollHeight : 0;
      const opacity = isExpanded ? 1 : 0;

      this.setState({
        isAnimating: true,
        maxHeight,
        opacity,
      });

      this.animatingTimeout = setTimeout(() => {
        this.setState({ isAnimating: false });
      }, animationSpeed || 300);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleHeightChange);
    }
    if (this.animatingTimeout !== undefined) {
      clearTimeout(this.animatingTimeout);
    }

    this.contentObserver?.disconnect();
  }

  handleHeightChange = () => {
    const { isExpanded } = this.props;
    const { isAnimating, maxHeight } = this.state;

    if (isExpanded && !isAnimating && this.contentWrapper !== null) {
      const { scrollHeight = 0 } = this.contentWrapper.current || {};

      if (scrollHeight !== maxHeight) {
        this.setState({ maxHeight: scrollHeight, opacity: scrollHeight / maxHeight });
      }
    }
  };

  render() {
    const { isExpanded, animationSpeed, children, className } = this.props;

    const { isAnimating, maxHeight, opacity } = this.state;

    return (
      <div
        className={className}
        ref={this.contentWrapper}
        style={{
          // Make overflow visible when panel is expanded in case some element
          // such as a tooltip overflows contentWrapper container.
          overflow: isExpanded && !isAnimating ? 'visible' : 'hidden',
          maxHeight,
          opacity: isExpanded && !isAnimating ? 1 : !isExpanded && !isAnimating ? 0 : opacity,
          transition: `${animationSpeed || 300}ms ease-in-out`,
          transitionProperty: 'opacity, max-height',
        }}
      >
        {children}
      </div>
    );
  }
}

export default DynamicHeight;
